<template>
  <div>
    <dashboard />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import Dashboard from '@/views/Dashboard/Index'

export default {
  components: {
    Dashboard,
  },
}
</script>
<style></style>
